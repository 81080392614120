import React from "react";
import CardWithSound from "./CardWithSound"
import { GetImages } from "../util/api"
import { makeStyles } from "@material-ui/core/styles";

import {
  useQuery,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import axios from "axios";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    height: "100vh",
    overflow: "hidden",
    flexDirection: "row",
    justifyContent: "center",
  }
}));

function useSheet() {
  return useQuery("sheet", async () => {
    const { data } = await axios.get(
      "https://sheets.googleapis.com/v4/spreadsheets/1Tl0KCDP8rT1jH-vekHYv07n7elGvkxElS9XcnJavnV0/values/Semaine!A1:Z50?key=AIzaSyDoLARbbo5mteYjVUP6gGU136m5G236ma0"
    );

    const items = GetImages();

    var now = new Date();
    let timeIndex

    const headers = data.values[0]
    headers.splice(0, 2)
    for (let i = 0; i < headers.length; i++) {
      var time = new Date();
      let m = headers[i].slice(-2)
      let h = headers[i].slice(0, -2)
      time.setHours(h, m, 0);
      if (now > time) {
        timeIndex = i
      }
    }
    timeIndex = timeIndex + 2 //recover original position

    const imagesFromSheet = data.values.filter(function (a) {
      return a[timeIndex] === "TRUE"
    }).map(a => a[1]);

    const activeImages = items.filter(function (a) {
      return imagesFromSheet.includes(a.name)
    })

    return activeImages;
  });
}

function Cards(props) {
  const classes = useStyles();
  return (
    <QueryClientProvider client={queryClient}>
      <div className={classes.container}>
        <Images />
      </div>
    </QueryClientProvider>
  );
}

function Images() {
  const { status, data, error } = useSheet();

  return (
    <>
      {status === "loading" ? (
        "Loading..."
      ) : status === "error" ? (
        <span>Error: {error.message}</span>
      ) : (
            <>
              {
                data.map((item, index) => (
                  <CardWithSound item={item} key={index} />
                ))
              }
            </>
          )}
    </>
  );
}

export default Cards;
