import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useRouter } from './../util/router.js';
import useSound from 'use-sound';

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  media: {
    height: 500,
    backgroundSize: "contain",
  },
  avatarWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: -(theme.spacing(15) / 2),
  },
  avatarBorder: {
    borderRadius: "50%",
    padding: "7px",
    backgroundColor: "white",
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  cell: {
    margin: "5px",
    textAlign: "center",
    flexGrow: 1,
    maxWidth: 600,
    backgroundPosition: "center",
    minWidth: "25%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  }
}));

function CardWithSound(props) {
  const item = props.item;
  const classes = useStyles();

  const [play, exposedData] = useSound(item.sound, {
    onend: () => {
      // router.push(`/images/${item.name}`);
    },
  });
  const router = useRouter();

  const openCard = () => {
    if (!exposedData.isPlaying) {
      play();
      router.push(`/images/${item.name}`);
    }
  }

  return (
    <div onClick={openCard} className={classes.cell} style={{ backgroundImage: `url('${item.image}')` }}>
    </div >
  );
}

export default CardWithSound;
