import React from "react";
import Cards from "../components/Cards";

function IndexPage(props) {
  return (
    <Cards
      bgColor="default"
      size="medium"
      bgImage=""
      bgImageOpacity={1}
      title="Bonjour Juju 💗"
      subtitle=""
    />
  );
}

export default IndexPage;
