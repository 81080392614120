import React from "react";
import Section from "./Section";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import NavigationIcon from '@material-ui/icons/Navigation';
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import { useRouter } from './../util/router.js';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    fontSize: "1.5em",
    '& .MuiButton-startIcon': {
      transform: 'rotate(-90deg)',
    }
  },
}));


function Navbar() {
  const classes = useStyles();
  const router = useRouter();

  return (
    <Section size="auto">
      <AppBar position="static" color="transparent" elevation={0}>
        <Container disableGutters={true}>
          <Toolbar>
            <Button onClick={(e) => router.push('/')}
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<NavigationIcon />}
            >
              Retour
              </Button>
          </Toolbar>
        </Container>
      </AppBar>

    </Section>

  );
}

export default Navbar;
