import React from "react";
import { useParams } from "react-router-dom";
import Navbar from "./../components/NavBar";

import { makeStyles } from "@material-ui/core/styles";
import { GetImage } from "../util/api"
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  cell: {
    textAlign: "center",
    flexGrow: 2,
    backgroundPosition: "center",
    minWidth: "50%",
    maxWidth: 600,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    height: "66vh",
    overflow: "hidden",
    flexDirection: "row",
    justifyContent: "center",
  }
}));

function CardPage() {
  let { name } = useParams();

  const item = GetImage(name);
  const classes = useStyles();

  return (



    <>
      <Navbar />
      <Container className={classes.container}>
        <div className={classes.cell} style={{ backgroundImage: `url('${item.image}')` }}></div >
      </Container>
    </>


  );
}

export default CardPage;
