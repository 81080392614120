import React from "react";
import IndexPage from "./index";
import CardPage from "./cardPage";
import { Switch, Route, Router } from "./../util/router.js";
import NotFoundPage from "./not-found.js";
import { ThemeProvider } from "./../util/theme.js";

function App(props) {
  return (
    <ThemeProvider>
      <Router>
        <>
          <Switch>
            <Route exact path="/" component={IndexPage} />
            <Route path="/images/:name" component={CardPage} />

            <Route component={NotFoundPage} />
          </Switch>
        </>
      </Router>
    </ThemeProvider>
  );
}

export default App;
