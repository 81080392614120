const images = [
  {
    image: "/images/pomme.jpg",
    name: "Pomme",
    sound: "/sounds/pomme.m4a"
  }, {
    image: "/images/guitare.jpg",
    name: "Guitare",
    sound: "/sounds/guitare.m4a"
  }, {
    image: "/images/tube.jpg",
    name: "Tube",
    sound: "/sounds/tube.m4a"
  },
  {
    image: "/images/dodo.jpg",
    name: "Dodo",
    sound: "/sounds/dodo.m4a"
  },
  {
    image: "/images/biscuits.jpg",
    name: "Biscuits",
    sound: "/sounds/biscuits.m4a"
  },
  {
    image: "/images/pudding.jpg",
    name: "Pudding",
    sound: "/sounds/pudding.m4a"
  },
  {
    image: "/images/lunettes.jpg",
    name: "Lunettes",
    sound: "/sounds/lunettes.m4a"
  },
  {
    image: "/images/party.jpg",
    name: "Party",
    sound: "/sounds/party.m4a"
  },
  {
    image: "/images/toilette.jpg",
    name: "Toilette",
    sound: "/sounds/toilette.m4a"
  },
  {
    image: "/images/manger.jpg",
    name: "Manger",
    sound: "/sounds/manger.m4a"
  },
  {
    image: "/images/eau.jpg",
    name: "Eau",
    sound: "/sounds/eau.m4a"
  },
];

export const GetImages = () => {
  return images
};

export const GetImage = (name) => {
  let image = {};
  images.some(function (el) {
    image = el;
    return el.name === name;
  });
  return image;
}